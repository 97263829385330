export class ToastMessageManager {
    private messageElement: JQuery;
    
    public constructor(messageElement: JQuery) 
    {
        let self = this;
        self.messageElement = messageElement;
    }

    public toast() {
        let self = this;
        self.showCommentPostSuccessfullMessage(); 
        setTimeout(function() {
            self.hideCommentPostSuccessfullMessage();
        }, 4000);
    }
    
    private showCommentPostSuccessfullMessage() {
        let self = this;
        var postSuccessfullMessageContainer = self.messageElement;
        postSuccessfullMessageContainer.show(1000);
    }

    private hideCommentPostSuccessfullMessage() {
        let self = this;
        var postSuccessfullMessageContainer = self.messageElement;
        postSuccessfullMessageContainer.hide(1000);
    }

}
