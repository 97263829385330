$(document).ready(function () {

    $(".dropdown-toggle").mouseenter(function (e) {
        let self = $(this);
        self.closest(".dropdown").addClass("open");
    })
        .mouseout(function (e) {
            let self = $(this);
            self.closest(".dropdown").removeClass("open");
        });

    $(".dropdown-sub-menu").mouseover(function (e) {
        let self = $(this);
        self.children(".dropdown-menu").show();
    })
        .mouseout(function (e) {
            let self = $(this);
            self.children(".dropdown-menu").hide();
        });

    $(".dropdown .fa-caret-down").click(function(e) {
        let self = $(this);
        self.closest(".dropdown").toggleClass("open");
        e.stopPropagation();
        e.preventDefault();
    });

    $(".dropdown-sub-menu a .fa-caret-right").click(function(e) {
        let self = $(this);
        let linkElement = self.parent();
        linkElement.parent().children(".dropdown-menu").toggle();
        e.stopPropagation();
        e.preventDefault();
    });
});