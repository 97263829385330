import { InlineFormManager } from "./inlineForms/inlineFormManager"
import { RecaptchaLoader } from "./recaptchaLoader";
declare var grecaptcha: any;

export class ContactFormManager {

    private recaptchaLoader: RecaptchaLoader;

    public initialize() {
        let self = this;
        self.recaptchaLoader = new RecaptchaLoader();
        self.initializeContactForm();
    }

    private initializeContactForm(): void {
        let self = this;
        let formContainer = $("#contact-form-container");
        let inlineFormManager = new InlineFormManager();
        inlineFormManager.initialize(formContainer, (form: JQuery) => {
            self.onInitializedForm(inlineFormManager);
        });
    }


    private onInitializedForm(inlineFormManager: InlineFormManager) {
        let self = this;
        inlineFormManager.disableSubmitButton();
        self.recaptchaLoader.runWhenLoaded(() => { self.renderRecaptchaWidget(inlineFormManager); });
    }

    private renderRecaptchaWidget(inlineFormManager: InlineFormManager) {
        let self = this;
        var form = inlineFormManager.getCurrentForm();
        var recaptchaId = form.data("recaptcha-id");
        var sitekey = form.data("recaptcha-sitekey");

        grecaptcha.render(recaptchaId,
            {
                sitekey: sitekey,
                callback: function (token) {
                    if (token.length !== 0) {

                        self.setHiddenInputRecaptchaResponse(inlineFormManager, token);
                        self.enableSubmitButton(inlineFormManager);
                    }
                }
            });
    }

    private setHiddenInputRecaptchaResponse(inlineFormManager: InlineFormManager, token) {
        var form = inlineFormManager.getCurrentForm();
        var hiddenInputRecaptchaResponse = form.find('.recaptcha-response');
        hiddenInputRecaptchaResponse.val(token);
    }

    private enableSubmitButton(inlineFormManager: InlineFormManager): void {
        inlineFormManager.enableSubmitButton();
    }


}