import { InlineFormControl } from "./inlineFormControl"

export class InlineFormManager {
    private formContainer: JQuery;
    private inlineFormControl: InlineFormControl;
    private onInitializeForm: (form: JQuery) => void;

    public initialize(
        formContainer: JQuery,
        onInitializeForm: (form: JQuery) => void) 
    {
        let self = this;
        self.formContainer = formContainer;
        self.onInitializeForm = onInitializeForm;
        self.initializeForm();
    }

    private initializeForm() {
        let self = this;
        self.formContainer.find("form").first().each(function () {
            let formElement = $(this);
            self.inlineFormControl = new InlineFormControl(
                formElement, 
                (form) => { self.onInitializeForm(form);  }, 
                (responseContent) => { self.handleSubmitResponse(responseContent); });
            self.inlineFormControl.initialize();
        });
    }

    public getCurrentForm() : JQuery {
        let self = this;
        return self.inlineFormControl.getForm();
    }

    private getSubmitButton() : JQuery {
        let self = this;
        return self.inlineFormControl.getSubmitButton();
    }

    private handleSubmitResponse(responseContent: any) {
        let self = this;
        let responseContentAsHtml = $(responseContent);
        self.formContainer.empty().append(responseContentAsHtml);
        self.initializeForm();
        runOnReadyManager.run();
    }

    public enableSubmitButton() {
        let self = this;
        self.inlineFormControl.enableSubmitButton();
    }

    public disableSubmitButton() {
        let self = this;
        self.inlineFormControl.disableSubmitButton();
    }
}

declare var runOnReadyManager: RunOnReadyManager;

interface RunOnReadyManager {
    run()
}