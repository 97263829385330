export class RecaptchaLoader {
    private isLoaded:boolean;
    private onloadCallbacks : (() => void)[];

    constructor() {
        this.onloadCallbacks = [];
        this.registerGlobalFunction();
    }

    public runWhenLoaded(onloadCallback: () => void): void {
        this.onloadCallbacks.push(onloadCallback);
        this.executeCallbacksIfLoaded();
    }

    private registerGlobalFunction() : void {
        let self = this;
        let global = (window) as any;
        let currentGlobalOnReCaptchaLoaded = global.OnReCaptchaLoaded;
        let newOnGlobalReCaptchaLoaded = () => {
            self.loaded();
            self.executeCallbacksIfLoaded();
            if (currentGlobalOnReCaptchaLoaded) {
                currentGlobalOnReCaptchaLoaded();
            }
        }

        global.OnReCaptchaLoaded = newOnGlobalReCaptchaLoaded;
    }

    private loaded(): void {
        this.isLoaded = true;
    }

    private executeCallbacksIfLoaded():void {
        if (this.isLoaded) {
            let callback = this.onloadCallbacks.shift();
            while (callback != null) {
                callback();
                callback = this.onloadCallbacks.shift();
            }
        }
    }
}