export class InlineFormControl {
    private formAsJQuery: JQuery;
    private submitButton: JQuery;
    private onInitializeForm: (form: JQuery) => void;
    private onSubmittedResponse: (data: any) => void;

    constructor(
        formAsJQuery: JQuery,
        onInitializeForm: (form: JQuery) => void,
        onSubmittedResponse: (data: any) => void) {
        let self = this;
        self.onInitializeForm = onInitializeForm;
        self.onSubmittedResponse = onSubmittedResponse;
        self.formAsJQuery = formAsJQuery;
    }

    public initialize() {
        let self = this;
        self.registerSubmitButton();
        self.initializeUnubtrusiveValidation();
        self.initializeFormSubmit();
        self.onInitializeForm(self.formAsJQuery);
    }

    private registerSubmitButton() {
        let self = this;
        self.submitButton = self.formAsJQuery.find(".js-submit-button");
    }

    public getSubmitButton() : JQuery {
        let self = this;
        return self.submitButton;
    }

    private initializeUnubtrusiveValidation() {
        let self = this;
        $.validator.unobtrusive.parse(self.formAsJQuery);
    }

    private initializeFormSubmit() {
        let self = this;
        self.formAsJQuery.on('submit',
            event => {
                this.submitForm();
                return false;
            }
        );
    }

    private submitForm() {
        let self = this;
        if (!self.formAsJQuery.valid()) {
            return;
        }
        self.submitAsAjax();
    }

    private getFormData(): FormData {
        let self = this;
        let formAsHtmlElement: HTMLFormElement = self.formAsJQuery[0] as HTMLFormElement;
        let formData = new FormData(formAsHtmlElement);

        return formData;
    }

    private submitAsAjax() {
        let self = this;

        var form = self.formAsJQuery;
        var formData = self.getFormData();
        $.ajax({
            type: form.attr("method"),
            url: form.attr("action"),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            success: function (data) {
                self.onSubmittedResponse(data);
            }
        });

    }

    public getForm() : JQuery {
        let self = this;
        return self.formAsJQuery;
    }

    public enableSubmitButton() {
        let self = this;
        self.submitButton.prop('disabled', false);
    }

    public disableSubmitButton() {
        let self = this;
        self.submitButton.prop('disabled', true);
    }
}