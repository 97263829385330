export class InlineFormManager {
    private formContainer: JQuery;
    private inlineFormControl: InlineFormControl;
    private onInitializeForm: (form: JQuery) => void;

    public initialize(
        formContainer: JQuery,
        onInitializeForm: (form: JQuery) => void) 
    {
        let self = this;
        self.formContainer = formContainer;
        self.onInitializeForm = onInitializeForm;
        self.initializeForm();
    }

    private initializeForm() {
        let self = this;
        self.formContainer.find("form").first().each(function () {
            let formElement = $(this);
            self.inlineFormControl = new InlineFormControl(
                formElement, 
                (form) => { self.onInitializeForm(form);  }, 
                (responseContent) => { self.handleSubmitResponse(responseContent); });
            self.inlineFormControl.initialize();
        });
    }

    public getCurrentForm() : JQuery {
        let self = this;
        return self.inlineFormControl.getForm();
    }

    private getSubmitButton() : JQuery {
        let self = this;
        return self.inlineFormControl.getSubmitButton();
    }

    private handleSubmitResponse(responseContent: any) {
        let self = this;
        let responseContentAsHtml = $(responseContent);
        self.formContainer.empty().append(responseContentAsHtml);
        self.initializeForm();
        runOnReadyManager.run();
    }

    public enableSubmitButton() {
        let self = this;
        self.inlineFormControl.enableSubmitButton();
    }

    public disableSubmitButton() {
        let self = this;
        self.inlineFormControl.disableSubmitButton();
    }
}

export class InlineFormControl {
    private formAsJQuery: JQuery;
    private submitButton: JQuery;
    private onInitializeForm: (form: JQuery) => void;
    private onSubmittedResponse: (data: any) => void;

    constructor(
        formAsJQuery: JQuery,
        onInitializeForm: (form: JQuery) => void,
        onSubmittedResponse: (data: any) => void) {
        let self = this;
        self.onInitializeForm = onInitializeForm;
        self.onSubmittedResponse = onSubmittedResponse;
        self.formAsJQuery = formAsJQuery;
    }

    public initialize() {
        let self = this;
        self.registerSubmitButton();
        self.initializeUnubtrusiveValidation();
        self.initializeFormSubmit();
        self.onInitializeForm(self.formAsJQuery);
    }

    private registerSubmitButton() {
        let self = this;
        self.submitButton = self.formAsJQuery.find(".js-submit-button");
    }

    public getSubmitButton() : JQuery {
        let self = this;
        return self.submitButton;
    }

    private initializeUnubtrusiveValidation() {
        let self = this;
        $.validator.unobtrusive.parse(self.formAsJQuery);
    }

    private initializeFormSubmit() {
        let self = this;
        self.formAsJQuery.on('submit',
            event => {
                this.submitForm();
                return false;
            }
        );
    }

    private submitForm() {
        let self = this;
        if (!self.formAsJQuery.valid()) {
            return;
        }
        self.submitAsAjax();
    }

    private getFormData(): FormData {
        let self = this;
        let formAsHtmlElement: HTMLFormElement = self.formAsJQuery[0] as HTMLFormElement;
        let formData = new FormData(formAsHtmlElement);

        return formData;
    }

    private submitAsAjax() {
        let self = this;

        var form = self.formAsJQuery;
        var formData = self.getFormData();
        $.ajax({
            type: form.attr("method"),
            url: form.attr("action"),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            success: function (data) {
                self.onSubmittedResponse(data);
            }
        });

    }

    public getForm() : JQuery {
        let self = this;
        return self.formAsJQuery;
    }

    public enableSubmitButton() {
        let self = this;
        self.submitButton.prop('disabled', false);
    }

    public disableSubmitButton() {
        let self = this;
        self.submitButton.prop('disabled', true);
    }
}

declare var runOnReadyManager: RunOnReadyManager;

interface RunOnReadyManager {
    run()
}